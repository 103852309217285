<template>
  <screen_width_cont>
    <div class="myBodyGeneral">
      <div class="myHeader" ref="myHeader_misTransac">
        <div class="cont_300_basico ancho_acotado">
          <div class="misTransacHeader header_top">
            <headerBack @back="backLocal" />
          </div>

          <div class="misTransacTitle title">
            {{ t("app.home.mis_transacciones") }}
          </div>

          <div class="misTransacSubTitle">
            {{ t("app.mis_transacciones.historial") }}
          </div>
        </div>
      </div>
    </div>

    <blurComp
      class="blur_sup"
      :style="blur_sup_misTransac"
      :scroll_to_="scroll_to_top"
      tipo="sup"
    />

    <div
      class="contenido centrado"
      :style="contMisTransac"
      v-show="cont_visible"
      @scroll="onScroll"
      ref="contenido_misTransac"
    >
      <div class="cont_300_basico ancho_acotado">
        <div class="misTransacBody">
          <div class="misTransacList">
            <div v-if="ultimasTransacciones.length > 0">
              <div v-for="(transaccion, i) in ultimasTransacciones" :key="i">
                <cardTransaction
                  :transaction="transaccion"
                  :index="i"
                  @transactionSelected="transactionSelectedLocal"
                  :transactionSelectedIndex="transactionSelectedIndex"
                  @mostrarDetalleTransac="mostrarDetalleTransacLocal"
                  @repetirTransac="repetirTransacLocal"
                  @repetirTransac_bloqueado="repetirTransac_bloqueado"
                />
              </div>
            </div>

            <div v-else class="home_sin_transac">
              <div class="home_sin_transac_Img">
                <img :src="sin_transacciones" alt="" srcset="" />
              </div>
              <div class="home_sin_transac_Tit">
                {{ t("app.home.sin_transac_text1") }}
              </div>
              <div class="home_sin_transac_Text">
                {{ t("app.home.sin_transac_text2") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <blurComp
      class="blur_inf"
      :style="blur_inf_misTransac"
      :scroll_to_="scroll_to_bottom"
      tipo="inf"
    />

    <div class="footer" ref="footer_misTransac">
      <div class="misTransacText1">
        {{ t("app.mis_transacciones.need_help") }}
      </div>
      <div class="misTransacText2">
        {{ t("app.mis_transacciones.contactate") }}
      </div>
      <div class="misTransacText3">
        <img :src="iconWhatsApp_1" alt="" srcset="" />
      </div>

      <!-- <div class="misTransacFooter">
        <enviosSeguros @callEnvioSeguroModal_="callEnvioSeguroModal" />
      </div> -->
    </div>

    <!-- <div class="cont_300_basico ancho_acotado"> -->

    <!-- </div> -->
  </screen_width_cont>
</template>

<script>
import headerBack from "../components/generales/headerBack.vue";
import { menuController, modalController, alertController } from "@ionic/vue";
import { useI18n } from "vue-i18n";
import envioSeguroHome from "./modales/enviosSegurosHome.vue";
import { mapActions, mapMutations, mapState } from "vuex";
import cardTransaction from "../components/generales/cardTransaction.vue";
import reciboTransferencia from "./modales/reciboTransferencia.vue";
// import enviosSeguros from "../components/generales/envios_seguros.vue";
import screen_width_cont from "../components/menuSp/screen_width_cont.vue";
import blurComp from "../components/generales/blur.vue";
import {
  blur_sup_scroll,
  blur_inf_scroll,
  contenido_style,
  blur_sup_style,
  blur_inf_style,
} from "../assets/funciones/blur";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },

  name: "misTransac",
  components: {
    headerBack,
    cardTransaction,
    // enviosSeguros,
    screen_width_cont,
    blurComp,
  },
  data() {
    return {
      iconWhatsApp_1: require("../assets/imgs/iconWhatsApp_1.svg"),
      transactionSelectedIndex: -1,
      scroll_to_bottom: true,
      scroll_to_top: true,
      contMisTransac: {},
      blur_sup_misTransac: {},
      blur_inf_misTransac: {},
      header: 0,
      footer: 0,
      cont_visible: false,
      sin_transacciones: require("../assets/imgs/home/sin_transac.svg"),
    };
  },
  computed: {
    ...mapState([
      "menuSpDesde",
      "remitente",
      "configuracion",
      "desde",
      "nav_stack",
      "heigthScreen",
      "widthScreen",
      "plataforma",
    ]),

    ultimasTransacciones() {
      let aux = [];
      if (Object.keys(this.remitente).length > 0) {
        let aux1 = Object.entries(this.remitente.destinatarios);
        let objTransaction = {};
        aux1.forEach((destin) => {
          if (
            destin[1].ultimas_transacciones &&
            destin[1].ultimas_transacciones.length > 0
          ) {
            destin[1].ultimas_transacciones.forEach((transac) => {
              objTransaction = {};
              // objTransaction = {};
              if (transac.banco_nombre) {
                objTransaction.banco = transac.banco_nombre;
              } else if (transac.nombre) {
                objTransaction.banco = transac.nombre;
              }

              objTransaction.id_persona = transac.destinatario_id;
              objTransaction.moneda_destino_id = transac.moneda_destino;
              objTransaction.moneda_destino_nombre =
                transac.moneda_destino_nombre;
              objTransaction.monto_origen = transac.monto_inicio;
              objTransaction.monto_pagar = transac.monto_inicio;
              objTransaction.monto_destino = transac.monto_destino;
              objTransaction.pais_destino = transac.pais_destino_id;
              objTransaction.mensaje_destinatario =
                transac.mensaje_destinatario;

              objTransaction.vigencia_pais =
                this.configuracion.pais[
                  `${transac.pais_destino_id}_${transac.moneda_destino}`
                ].pausa == "1" ||
                this.configuracion.pais[
                  `${transac.pais_destino_id}_${transac.moneda_destino}`
                ].activo == "0"
                  ? false
                  : true;

              if (
                transac.destinatario_id &&
                transac.destinatario_forma_entrega_id &&
                this.remitente.destinatarios[transac.destinatario_id]
                  .detalle_entrega
              ) {
                objTransaction.vigencia_destinatario =
                  !this.remitente.destinatarios[transac.destinatario_id] ||
                  this.remitente.destinatarios[transac.destinatario_id]
                    .activo == "0" ||
                  !this.remitente.destinatarios[transac.destinatario_id]
                    .detalle_entrega[transac.destinatario_forma_entrega_id]
                    ? false
                    : true;
              } else {
                objTransaction.vigencia_destinatario = false;
              }

              objTransaction.destinatario_nombres = `${transac.destinatario_nombre_1} ${transac.destinatario_apellido_1} ${transac.destinatario_apellido_2}`;
              objTransaction.fecha_ingreso = transac.fecha_inscripcion;
              objTransaction.hora_ingreso = transac.hora_ingreso;
              objTransaction.destinatario_apellido1 =
                transac.destinatario_apellido_1;
              objTransaction.destinatario_apellido2 =
                transac.destinatario_apellido_2;
              if (transac.banco_nombre) {
                objTransaction.destinatario_banco_destino_nombre =
                  transac.banco_nombre;
              } else if (transac.nombre) {
                objTransaction.destinatario_banco_destino_nombre =
                  transac.nombre;
              }

              objTransaction.pais_destino_nombre = transac.pais_destino_nombre;

              objTransaction.id_estado_pago = transac.id_estado_pago;
              objTransaction.id_estado_envio = transac.id_estado_envio;
              objTransaction.id_estado_retiro = transac.id_estado_retiro;

              objTransaction.bancos_id = transac.bancos_id;
              objTransaction.bancos_tipo_cuentas_id =
                transac.bancos_tipo_cuentas_id;
              objTransaction.nro_cuenta = transac.nro_cuenta;
              objTransaction.motivo_transaccion = transac.motivo_transaccion_id;

              if (transac.forma_entrega_id) {
                objTransaction.forma_entrega_id = transac.forma_entrega_id;
              }
              if (transac.destinatario_forma_entrega_id) {
                objTransaction.detalle_entrega_id =
                  transac.destinatario_forma_entrega_id;
              }
              objTransaction.tipos_cuenta_nombre = transac.tipos_cuenta_nombre;
              objTransaction.mensaje_destinatario =
                transac.mensaje_destinatario;
              objTransaction.transaccion_id = transac.transaccion_id;

              if (
                transac.id_estado_pago != "3" &&
                transac.id_estado_envio != "4" &&
                transac.id_estado_envio != "5" &&
                transac.id_estado_envio != "6" &&
                transac.id_estado_retiro != "3"
              ){
                aux.push(objTransaction);
              }
              // aux.push(objTransaction);
            });
          }
        });

        aux.sort((a, b) => {
          let a_f = new Date(`${a.fecha_ingreso} ${a.hora_ingreso}`);
          let b_f = new Date(`${b.fecha_ingreso} ${b.hora_ingreso}`);
          if (a_f > b_f) {
            return -1;
          }
          if (a_f < b_f) {
            return 1;
          }
          return 0;
        });
      }

      return aux;
    },
  },
  methods: {
    ...mapMutations([
      "updateBloquearNavegacion",
      "updateTransaccion",
      "set_nav_stack",
      "setshowSpinner",
      "updateDesde",
    ]),
    ...mapActions([
      "formarReciboTransferencia",
      "formatCotizadorResumen",
      "formatNumber",
      "reload_tasas_costo_serv_pausa",
      "repetirTransaccion",
    ]),

    async callEnvioSeguroModal() {
      this.updateBloquearNavegacion(true);
      const modal = await modalController.create({
        component: envioSeguroHome,
        // cssClass: 'my-custom-class',
        // componentProps: {
        //   dataTransfer: data
        // },
      });
      modal.onDidDismiss().then(() => {
        this.updateBloquearNavegacion(false);
      });
      return modal.present();
    },
    backLocal() {
      // if(this.menuSpDesde.menuSp) menuController.open("firstMenu");
      // if(this.desde == "beneficiarioNuevo"){
      //   this.$router.push({ name: "nuevoDestinatarioForm", replace: true });
      // }else{
      //   this.$router.push({ name: this.menuSpDesde.desde, replace: true });
      // }

      if (this.nav_stack.menuSp) {
        menuController.open("firstMenu");
      }
      let aux = [...this.nav_stack.desde];
      let destino = aux.pop();
      this.set_nav_stack({ desde: aux, menuSp: false });
      this.$router.push({ name: destino, replace: true });
    },
    transactionSelectedLocal(data) {
      this.transactionSelectedIndex = data;
    },
    async mostrarDetalleTransacLocal(data) {
      this.updateBloquearNavegacion(true);
      this.formarReciboTransferencia(this.ultimasTransacciones[data]);
      const modal = await modalController.create({
        component: reciboTransferencia,
        // cssClass: 'my-custom-class',
        // componentProps: {
        //   dataTransfer: this.ultimasTransacciones[data]
        // },
      });

      modal.onDidDismiss().then(() => {
        this.updateBloquearNavegacion(false);

        // if (data.data.registrar) {
        //   this.$router.push({ name: "registro" });
        // }
      });
      return modal.present();
    },
    async repetirTransacLocal(index) {
      this.setshowSpinner(true);
      await this.reload_tasas_costo_serv_pausa();

      this.setshowSpinner(false);

      this.updateTransaccion({
        id_persona: this.ultimasTransacciones[index].id_persona,
        pais_destino: `${this.ultimasTransacciones[index].pais_destino}_${this.ultimasTransacciones[index].moneda_destino_id}`,
        detalle_entrega_id: this.ultimasTransacciones[index].detalle_entrega_id,
        monto_destino: this.ultimasTransacciones[index].monto_destino,
      });

      this.$router.push({
        name: "repetir_giro",
        replace: true,
      });
    },
    async repetirTransac_bloqueado(motivo) {
      let texto = "";
      if (motivo == "pais") {
        texto = this.t("app.home.destino_bloqueado");
      } else if (motivo == "destinatario") {
        texto = this.t("app.home.destinatario_error");
      }
      let alert = await alertController.create({
        // header: this.title,
        subHeader: texto,
        buttons: ["Ok"],
        backdropDismiss: false,
      });
      await alert.present();
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      this.scroll_to_top = blur_sup_scroll(scrollTop);
      this.scroll_to_bottom = blur_inf_scroll(
        scrollTop,
        clientHeight,
        scrollHeight
      );
    },
  },
  watch: {
    heigthScreen() {
      this.contMisTransac = contenido_style(
        this.heigthScreen,
        this.header,
        this.footer,
        this.plataforma == "web" ? 63 : 0
      );
    },
  },
  ionViewWillEnter() {
    this.enVista = true;
    this.updateDesde("misTransac");
  },
  ionViewDidEnter() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.header = this.$refs["myHeader_misTransac"].offsetHeight;
        this.footer = this.$refs["footer_misTransac"].offsetHeight;
        this.cont_visible = true;
        this.contMisTransac = contenido_style(
          this.heigthScreen,
          this.header,
          this.footer,
          this.plataforma == "web" ? 63 : 0
        );
        this.blur_sup_misTransac = blur_sup_style(this.header);
        this.blur_inf_misTransac = blur_inf_style(this.footer);
      }, 100);

      setTimeout(() => {
        if (
          this.$refs["contenido_misTransac"].scrollHeight >
          this.$refs["contenido_misTransac"].clientHeight
        ) {
          this.scroll_to_bottom = false;
        }
      }, 100);
    });
  },
  ionViewWillLeave() {
    this.enVista = false;
  },
};
</script>

<style>
.misTransacHeader {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.misTransacTitle {
  margin-top: 1.75rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;

  letter-spacing: 1px;
  color: #801b1c;
}
.misTransacSubTitle {
  margin-top: 1.5625rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #614ad3;
}
.misTransacBody {
  margin-top: 1.125rem;
  /* height: 20.5rem; */
  /* border: 1px solid green; */
  overflow-y: auto;
}
.misTransacText1 {
  margin-top: 1.125rem;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #801b1c;
}
.misTransacText2 {
  margin-top: 0.2rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #614ad3;
}
.misTransacText3 {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
/* .misTransacFooter {
  margin-top: 0.9375rem;
  padding-bottom: 1rem;
} */
/* .misTransacDestacado {
  text-decoration: underline;
} */
</style>